import { type LucideProps } from 'lucide-react';
import { forwardRef } from 'react';

import { cn } from '@/utils';

export const StarFilled = forwardRef<SVGSVGElement, LucideProps>(
  (props, ref) => (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      className={cn('fill-white', props.className)}
    >
      <g clipPath='url(#clip0_591_5550)'>
        <path
          d='M5.75088 5.20246L1.85607 5.76715L1.78709 5.78119C1.68266 5.80891 1.58746 5.86385 1.51121 5.9404C1.43496 6.01695 1.38039 6.11236 1.35308 6.2169C1.32577 6.32143 1.32669 6.43135 1.35575 6.53541C1.3848 6.63947 1.44096 6.73396 1.51848 6.80922L4.34008 9.55573L3.67467 13.4353L3.66673 13.5024C3.66034 13.6104 3.68276 13.7182 3.73171 13.8147C3.78066 13.9112 3.85438 13.9929 3.94531 14.0516C4.03624 14.1102 4.14112 14.1436 4.24921 14.1484C4.3573 14.1531 4.46472 14.1291 4.56046 14.0787L8.04381 12.2473L11.5192 14.0787L11.5803 14.1068C11.681 14.1465 11.7905 14.1587 11.8976 14.1421C12.0046 14.1255 12.1053 14.0807 12.1893 14.0123C12.2733 13.944 12.3376 13.8545 12.3756 13.7531C12.4136 13.6517 12.424 13.542 12.4056 13.4353L11.7396 9.55573L14.5624 6.80861L14.61 6.75672C14.6781 6.67295 14.7227 6.57264 14.7393 6.46601C14.7559 6.35939 14.744 6.25026 14.7047 6.14975C14.6654 6.04924 14.6002 5.96093 14.5157 5.89383C14.4312 5.82673 14.3304 5.78323 14.2236 5.76776L10.3288 5.20246L8.58774 1.67394C8.53736 1.5717 8.45937 1.48562 8.36259 1.42542C8.26582 1.36522 8.15412 1.33331 8.04015 1.33331C7.92618 1.33331 7.81448 1.36522 7.7177 1.42542C7.62093 1.48562 7.54293 1.5717 7.49255 1.67394L5.75088 5.20246Z'
          fill='#475467'
        />
      </g>
      <defs>
        <clipPath id='clip0_591_5550'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
);

StarFilled.displayName = 'StarFilled';
