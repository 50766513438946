import { createPathGenerator } from './utils';

export const pathToSettings = createPathGenerator('/settings');

export const pathToSettingsBusinessInfo =
  pathToSettings.createChildPath('business-info');

export const pathToSettingsProposalTemplate = pathToSettings.createChildPath(
  'proposal-template/:id',
);

export const pathToSettingsProposalTemplates =
  pathToSettings.createChildPath('proposal-templates');

export const pathToSettingsIntegrations =
  pathToSettings.createChildPath('integrations');

export const pathToSettingsFormBuilder =
  pathToSettings.createChildPath('form-builder');

export const pathToSettingsTeam = pathToSettings.createChildPath('team');

export const pathToSettingsTeamMember =
  pathToSettingsTeam.createChildPath(':id');
