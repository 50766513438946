import { createPathGenerator } from './utils';

export const pathToOfficeAppointments = createPathGenerator('/appointments');
export const pathToOfficeAppointmentsNew =
  pathToOfficeAppointments.createChildPath('new');
export const pathToOfficeAppointmentsManageReports =
  pathToOfficeAppointments.createChildPath('manage-reports/:id');
export const pathToOfficeAppointmentsAddReport =
  pathToOfficeAppointments.createChildPath('add-report/:id');
export const pathToOfficeAppointmentsEdit =
  pathToOfficeAppointments.createChildPath('edit/:id');
export const pathToOfficeAppointmentsBulkEdit =
  pathToOfficeAppointments.createChildPath('bulk-edit');
export const pathToOfficeAppointmentsShow =
  pathToOfficeAppointments.createChildPath(':id');
export const pathToOfficeAppointmentsSuggested =
  pathToOfficeAppointments.createChildPath('suggested/:id');

export const pathToOfficeAppointment = createPathGenerator(
  '/office/appointments/:appointmentId',
);
export const pathToOfficeAppointmentReports =
  pathToOfficeAppointment.createChildPath('reports');
export const pathToOfficeAppointmentReport =
  pathToOfficeAppointmentReports.createChildPath(':reportId');
export const pathToOfficeAppointmentReportPreview =
  pathToOfficeAppointmentReport.createChildPath('preview');
export const pathToOfficeAppointmentReportEdit =
  pathToOfficeAppointmentReport.createChildPath('edit');
export const pathToOfficeAppointmentReportEmailComposer =
  pathToOfficeAppointmentReport.createChildPath('send');

export const pathToOfficeCustomers = createPathGenerator('/customers');
export const pathToOfficeCustomersNew =
  pathToOfficeCustomers.createChildPath('new');
export const pathToOfficeCustomersEdit =
  pathToOfficeCustomers.createChildPath('edit/:id');
export const pathToOfficeCustomerLinkToQuickBooks =
  pathToOfficeCustomers.createChildPath('link-to-quickbooks/:id');

export const pathToOfficeCustomer =
  pathToOfficeCustomers.createChildPath(':customerId');
export const pathToOfficeCustomerReports =
  pathToOfficeCustomer.createChildPath('reports');
export const pathToOfficeCustomerAppointments =
  pathToOfficeCustomer.createChildPath('appointments');
export const pathToOfficeCustomerLocations =
  pathToOfficeCustomer.createChildPath('locations');
export const pathToOfficeCustomerProposals =
  pathToOfficeCustomer.createChildPath('proposals');
export const pathToOfficeCustomerInvoices =
  pathToOfficeCustomer.createChildPath('invoices');
export const pathToOfficeLocations = createPathGenerator('/locations');
export const pathToOfficeLocationLinkToTce =
  pathToOfficeLocations.createChildPath('link-to-tce/:id');
export const pathToOfficeLocationLinkToQuickBooks =
  pathToOfficeLocations.createChildPath('link-to-quickbooks/:id');

export const pathToOfficeLocation =
  pathToOfficeLocations.createChildPath(':locationId');
export const pathToOfficeLocationReports =
  pathToOfficeLocation.createChildPath('reports');
export const pathToOfficeLocationAttachments =
  pathToOfficeLocation.createChildPath('attachments');
export const pathToOfficeLocationAttachmentsAppointmentShow =
  pathToOfficeLocationAttachments.createChildPath(':appointmentId');
export const pathToOfficeLocationAttachmentsAppointmentEdit =
  pathToOfficeLocationAttachments.createChildPath(':appointmentId/edit');
export const pathToOfficeLocationAttachmentsAddReport =
  pathToOfficeLocationAttachments.createChildPath(':appointmentId/add-report');
export const pathToOfficeLocationAttachmentsManageReports =
  pathToOfficeLocationAttachments.createChildPath(
    ':appointmentId/manage-reports',
  );
export const pathToOfficeLocationAppointments =
  pathToOfficeLocation.createChildPath('appointments');
export const pathToOfficeLocationSystems =
  pathToOfficeLocation.createChildPath('systems');
export const pathToOfficeLocationProposals =
  pathToOfficeLocation.createChildPath('proposals');
export const pathToOfficeLocationInvoices =
  pathToOfficeLocation.createChildPath('invoices');
export const pathToOfficeLocationDeficiencies =
  pathToOfficeLocation.createChildPath('deficiencies');
export const pathToOfficeLocationDeficiency =
  pathToOfficeLocationDeficiencies.createChildPath(':deficiencyId');
export const pathToOfficeLocationDeficiencyAdditionalNotes =
  pathToOfficeLocationDeficiencies.createChildPath(
    'additional-notes/:formNoteId',
  );
