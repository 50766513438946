import { cva, type VariantProps } from 'class-variance-authority';
import { type BaseHTMLAttributes, type ReactNode, forwardRef } from 'react';

import { cn } from '@/utils';

const headingVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-content-primary',
      inversePrimary: 'text-content-inversePrimary',
      secondary: 'text-content-secondary',
      tertiary: 'text-content-tertiary',
      disabled: 'text-content-disabled',
      placeholder: 'text-content-placeholder',
      disabledOnSurface: 'text-content-disabledOnSurface',
      placeholderOnSurface: 'text-content-placeholderOnSurface',
      criticalOnSurface: 'text-content-criticalOnSurface',
      warningOnSurface: 'text-content-warningOnSurface',
      informative: 'text-content-informative',
      inverseInformative: 'text-content-inverseInformative',
      informativeOnSurface: 'text-content-informativeOnSurface',
      successOnSurface: 'text-content-successOnSurface',
    },
    size: {
      xxl: 'text-4xl font-bold', // 64px
      xl: 'text-3xl font-bold', // 48px
      lg: 'text-2xl font-bold', // 32px
      md: 'text-xl font-semibold', // 24px
      sm: 'text-lg font-semibold', // 20px
      xs: 'text-md font-semibold', // 16px
      xxs: 'text-sm font-medium', // 14px
      allCaps: 'text-xs font-semibold uppercase tracking-wider', // 11px
    },
    clamp: {
      '1': 'line-clamp-1',
      '2': 'line-clamp-2',
      '3': 'line-clamp-3',
      '4': 'line-clamp-4',
      '5': 'line-clamp-5',
      '6': 'line-clamp-6',
      none: 'line-clamp-none',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
    clamp: 'none',
  },
});

type HeadingSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const sizeToHeadingMap: Record<string, HeadingSize> = {
  xxl: 'h1',
  xl: 'h1',
  lg: 'h2',
  md: 'h3',
  sm: 'h4',
  xs: 'h5',
  xxs: 'h6',
  allCaps: 'h6',
};

type Props = Pick<BaseHTMLAttributes<HTMLHeadingElement>, 'role'> &
  VariantProps<typeof headingVariants> & {
    as?: HeadingSize;
    className?: string;
    children: ReactNode;
  };

export const Heading = forwardRef<HTMLParagraphElement, Props>(
  ({ as, size, variant, clamp, className, ...props }, ref) => {
    const Comp = as || (size && sizeToHeadingMap[size]) || 'h1';

    return (
      <Comp
        className={cn(headingVariants({ variant, size, clamp }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);

Heading.displayName = 'Heading';
